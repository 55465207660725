import React from "react";
import { graphql } from "gatsby";
import {Helmet} from "react-helmet";

import DefaultLayout from "../components/layouts/default";
import Header from "../components/layouts/index/Header";
import TaglineList from '../components/taglinelist/TaglineList';

import transformTaglines from "../utils/transform-tagline";

export default class Index extends React.Component {
    taglineList = [];

    constructor(props) {
        super(props);

        this.experience = this.props.pageContext.experience;

        // setup static tagline list
        // slice list to shallow copy so we don't rearrange our props
        this.taglineList = transformTaglines(props.data.realTaglines.taglines)
            .slice().reverse();

        let experienceSlice = this.experience;
        if(experienceSlice === 'all') {
            experienceSlice = this.taglineList.length;
        }

        this.state = { taglines: this.taglineList.slice(this.taglineList.length - experienceSlice) };

        this.onChangeTaglines = this.onChangeTaglines.bind(this);
    }

    onChangeTaglines(taglines) {
        this.setState({ taglines: taglines });
    }

    render() {
        return (<DefaultLayout adCount={Math.min(this.state.taglines.length / 12, 10)}>
            <Helmet>
                <title>{ 'Every Real Housewive Tagline ranked!!' }</title>
            </Helmet>
            <Header experience={ this.experience } totalTaglines={ this.taglineList.length } />
            <TaglineList taglines={ this.state.taglines } showFilterMenu={ true } />
        </DefaultLayout>);
    }
}

export const query = graphql`
    query {
        realTaglines {
            taglines {
                edges {
                    node {
                        id
                        tagline
                        rank
                        slug
                        housewife {
                            fullName
                            slug
                            housewifeimageSet {
                                crops {
                                    height
                                    name
                                    url
                                    width
                                }
                            }
                        }
                        seasonList:season {
                            edges {
                                node {
                                    number
                                    franchise {
                                        acronym
                                        city
                                        slug
                                    }
                                }
                            }
                        }
                        taglinescore {
                            total
                        }
                    }
                }
            }
        }
    }
`